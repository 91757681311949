import { FC } from 'react';
import '../assets/Style.css';

export const Home: FC = () => {
  return (
    <div className="home">
      <h1 className="title">Socialko Account Management</h1>
    </div>
  );
};
